// this.leaveStatusFilter = new Element('select', { 'class': 'history-filter-select status-filter inline' }).adopt(
//   new Element('option', { 'value': '0', 'html': 'All', 'id': null }),
//   new Element('option', { 'value': '1', 'html': 'Approved', 'id': '3' }),
//   new Element('option', { 'value': '2', 'html': 'Declined', 'id': '2' }),
//   new Element('option', { 'value': '3', 'html': 'Pending', 'id': '0' }),
//   new Element('option', { 'value': '4', 'html': 'Cancelled', 'id': '6' })

export enum LeaveStatus {
  All = '',
  Approved = '3',
  Declined = '2',
  Pending = '0',
  Cancelled = '6'
}

export const LIST_DATE_FORMAT = 'ddd, DD MMM YYYY'
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD'
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'

export const leaveStatusByCode = (code: number) => {
  switch (code) {
    case Number(LeaveStatus.Approved):
      return 'Approved'
    case  Number(LeaveStatus.Declined):
      return 'Declined'
    case  Number(LeaveStatus.Pending):
      return 'Pending'
    case  Number(LeaveStatus.Cancelled):
      return 'Cancelled'
  }
}