import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, DatePickerProps, List, Row, Select, Spin, Typography } from 'antd';
const { Title } = Typography;
import dayjs from 'dayjs';
import remote from '@/remote/remote';
import { useStore } from '@/store/store';
import { LoadingOutlined } from '@ant-design/icons';

const availableLeaveCodes = ['07', '09', '10', '12', '13', '11'];
const TeamEstimateLeave: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showList, setShowList] = useState<boolean>(false);
  const [employeeSelectedDate, setEmployeeSelectedDate] = useState<string | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [employeeCodeBalances, setEmployeeCodeBalances] = useState<any[]>([]);
  const [selectedLeaveCode, setSelectedLeaveCode] = useState<string | null>(null);
  const [leaveCodes, setLeaveCodes] = useState<any[]>([]);

  const { state, dispatch } = useStore(); 

  const user = window.Affinity?.user;
  const selectedLeaveCodeDescription = useMemo(() => {
    const selectedLeave = leaveCodes.find((leaveCode) => leaveCode.value === selectedLeaveCode);
    return selectedLeave ? selectedLeave.label : '';
  }, [selectedLeaveCode, leaveCodes]);

  
  useEffect(() => {
    console.log('all leaveCodes', state.leaveCodes);
    // filter stat.leaveCodes use availableLeaveCodes
    const leaveCodes = state.leaveCodes.filter((leaveCode) => availableLeaveCodes.includes(leaveCode.leaveCode));
    console.log('leaveCodes', leaveCodes);

    setLeaveCodes(leaveCodes.map((leaveCode) => ({
      label: leaveCode.description,
      value: leaveCode.leaveCode
    })));
  }, [state.leaveCodes]);

  const handleEmployeeDateChange = (date: any, dateString: string) => {
    setEmployeeSelectedDate(dateString);
  }

  const onShowEmployeeEstimate = async () => {
    setIsLoading(true);
    console.log('dateString', employeeSelectedDate);
    const today = dayjs(employeeSelectedDate).format('DD-MMM-YYYY');

    if (!selectedEmployee) {
      return;
    }
    let param = {
      "dateTo": today,
      "includeIndirect": false,
      "employeeNo": selectedEmployee,
      "leaveCode": selectedLeaveCode,
      "pageNo": 1
    }

    try {
      const res = await remote.managerTeamLeaveBalance(user.employeeNo, param)
      console.log('res', res)
      const codeOptions = res.leaveConfigOptions;
      // console.log('res', res.data.componentBalances[0].codeBalances);
      // setLeaveBalance(res.componentBalances[0].codeBalances)
      setEmployeeCodeBalances(res.projectedLeave.map((item: any) => {
        return {
          ...item,
        }
      }));
      setShowList(true);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  }

  const onSelectEmployee = (value: number) => {
    console.log('selected emp', value);
    setSelectedEmployee(value);
  }

  return (
    <Card style={{ borderRadius: '8px', marginTop: '20px'}}>
        <Title level={4}>Team Estimated Leave</Title>
        <Row style={{marginTop: 16}}>
          <Select placeholder='Select Employee'
            style={{ width: 180}}
            options={state.employees.map((employee) => ({ label: employee.employeeName, value: employee.employeeNo}))}
            onChange={onSelectEmployee}
          ></Select>
        </Row>
        <Row style={{marginTop: 10}}>
          <Select placeholder='Select Leave Code'
            style={{ width: 180}}
            options={leaveCodes}
            onChange={(value) => setSelectedLeaveCode(value)}
            >

          </Select>
        </Row>
        <Row align='middle' style={{marginTop: 10}}>
          <DatePicker
            style={{ width: 180}}
            onChange={handleEmployeeDateChange} />
          <Button type="primary" onClick={onShowEmployeeEstimate} style={{ marginLeft: 8 }}>
          Show
        </Button>
        </Row>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} 
        spinning={isLoading}
        size="large" >
        {showList && (
          <Collapse
            ghost 
            style={{ marginTop: 16, minHeight: isLoading ? '200px' : 'auto'  }}
            className='estimate-leave-collapse'
            items={employeeCodeBalances.map((item) => ({
              key: item.leaveCode,
              label: <Row>
              <Col span={12}>{selectedLeaveCodeDescription}</Col>
              <Col span={12} style={{ textAlign: 'right'}}>{item.totalProjectedHours}</Col>
            </Row>,
            children: 
              <div>
                <Row style={{ marginBottom: '10px', fontWeight: 'bold'}}>
                  <Col span={18}>Breakdown</Col>
                  <Col span={6} style={{ textAlign: 'right'}}>Hours</Col>
                </Row>
                <Row style={{ marginBottom: '10px'}}>
                  <Col span={18}>Leave balance at last period end	</Col>
                  <Col span={6} style={{ textAlign: 'right'}}>{item.leaveBalance}</Col>
                </Row>
                <Row style={{ marginBottom: '10px'}}>
                  <Col span={12}>Add leave accruals</Col>
                  <Col span={12} style={{ textAlign: 'right'}}>{(item.accruedProjectedLeave).toFixed(2)}</Col>
                </Row>
                
                <List
                  dataSource={item.leaveItems}
                  grid={{ gutter: 10, column: 1 }}
                  renderItem={(leaveItem: any) => (
                    <List.Item>
                      <Row>
                        {
                          leaveItem.IsCancelledOrDeclinedLeavePaidInAdvance ?
                          <>
                            <Col span={12}>Credit cancelled/declined leave booked on {leaveItem.DateFrom}</Col> 
                            <Col span={12} style={{ textAlign: 'right'}}>+ {leaveItem.units}</Col>
                          </> :
                          <>
                            <Col span={12}>Subtract Leave from {leaveItem.DateFrom}</Col>
                            <Col span={12} style={{ textAlign: 'right'}}>- {leaveItem.units}</Col>
                          </>
                        }
                      </Row>
                    </List.Item>
                  )}> 
                </List>
                <Row style={{ marginBottom: '10px', fontWeight: 'bold'}}>
                  <Col span={18}>Total estimated leave available on {dayjs(selectedDate).format('DD/MM/YYYY')}</Col>
                  <Col span={6} style={{ textAlign: 'right'}}>{item.totalHours}</Col>
                </Row>
              </div>
              
          }))} defaultActiveKey={['1']}/>
        )}
        </Spin>
      </Card>
  );
};

export default TeamEstimateLeave;