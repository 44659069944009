import React, { useEffect, useState } from 'react';
import { Row, Select, Button, Typography, Modal, Spin, notification } from 'antd';
import { DeleteColumnOutlined, FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import TeamLeaveCard from './TeamLeaveCard';
import remote from '@/remote/remote';
import { useStore } from '@/store/store';
import { LeaveDetails } from '../types';
import TeamLeaveHistoryFilter, { DefaultTeamHistoryFilter } from './TeamLeaveHistoryFilter';
import LeaveDetail from '../leaveDetail/LeaveDetail';
import ApplyLeave from '../leaveDetail/ApplyLeave';

const { Text } = Typography;

// interface TeamLeaveTabProps {
//   teamMembers: { label: string; value: string }[];
//   teamLeaveData: { /* Define the structure of leave data here */ }[];
// }
const DefaultPagination = { current: 1, pageSize: 15, totalItems: null, totalPages: null };
const DefaultTeamLeaveHistoryParam = {
  PageNo: 1,
  StatusCode: 0,
  DateFrom: '02-Oct-2023',
  orderBy: 'DateSubmitted',
  isAscending: true,
}

const TeamLeaveTab = ({ }) => {
  const [teamLeaveData, setTeamLeaveData] = useState<LeaveDetails[]>([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [pagination, setPagination] = useState({...DefaultPagination});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLeaveDetailOpen, setIsLeaveDetailOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState<LeaveDetails | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
  const [filter, setFilter] = useState(DefaultTeamHistoryFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const { state, dispatch, updateSelectedLeave } = useStore(); 
  const user = window.Affinity?.user;

  useEffect(() => {
    async function fetchDataInit() {
      setIsLoading(true);
      await loadManagerConfig();
      await loadTeamLeaveHistory();
      setIsLoading(false);
    }
    
    fetchDataInit();
  }, [])

  const loadManagerConfig = () => {
    remote.getLeaveManagerConfig(user.employeeNo)
    .then((res: any) => {
      console.log(res);
      state.employees = res.employees
      dispatch({ type: 'SET_LEAVE_CONFIG', payload: res });
      dispatch({ type: 'SET_EMPLOYEES', payload: res.employees });
      dispatch({ type: 'SET_LEAVE_CODES', payload: res.leaveCodes });
      dispatch({ type: 'SET_ALL_LEAVE_CODES', payload: res.allCompanyLeaveCodes });
      setTeamMembers(res.employees.map((employee: any) => ({ label: employee.employeeName, value: employee.employeeNo })));
    })
  }

  const loadMoreLeaveHistory = async () => {
    setIsLoading(true);
    const newPagination = { ...pagination, current: pagination.current + 1 }
    setPagination(newPagination);
    await loadTeamLeaveHistory(newPagination);
    setIsLoading(false);
  }

  const loadTeamLeaveHistory = async (paginationInput?: any, newData?: boolean) => {
    console.log('load team leave history', filter);
    let params = {...DefaultTeamLeaveHistoryParam,
      StatusCode: filter.status === 'all' ? null : filter.status,
      LeaveCode: filter.leaveType === 'all' ? null : filter.leaveType, // todo: change to number??
      DateFrom: filter.dateFrom,
      DdateTo: filter.dateTo,
      OrderBy: filter.orderBy,
      includeIndirect: filter.indirect,
      excludeNonApprovers: filter.toAction,
      isAscending: filter.isAscending,
    }
    if (paginationInput) {
      params = {
        ...params, 
        PageNo: paginationInput.current,
      }
    }

    try {
      await remote.getManagerTeamLeaveHistory(user.employeeNo, params)
      .then((res: any) => {
  
        if (!paginationInput) {
          setPagination({
            ...pagination,
            totalPages: res.pageCount,
            totalItems: res.count,
          })
        }
        
        state.teamLeaveHistory = res
        console.log('team leave history', res);
        const newLeaveHistory = (res.history.map((leave: LeaveDetails) => ({
            employeeName: leave.employeeName,
            employeeNo: leave.employeeNo,
            dateFrom: leave.dateFrom,
            dateTo: leave.dateTo,
            leaveCode: leave.leaveCode,
            codeDescription: leave.codeDescription,
            status: leave.status,
            statusCode: leave.statusCode,
            totalDays: leave.totalDays,
            totalHours: leave.totalHours,
            unitType: leave.unitType,
            comment: leave.comment,
            authorisations: leave.authorisations,
            positionCode: leave.positionCode,
            positionTitle: leave.positionTitle,
            tsGroupId: leave.tSGroupId,
          //label: leave.employeeName,
          //value: leave.employeeNo 
        })));
        if (newData) {
          setTeamLeaveData([...newLeaveHistory]);
        } else {
          setTeamLeaveData([...teamLeaveData, ...newLeaveHistory]);
        }
        
      });
    } catch (error) {
      console.error('Failed to get leave history:', error);
    }
  }

  const onSelectEmployee = (employeeNo: number) => {
    console.log('selected employee', employeeNo);
    setSelectedEmployee(employeeNo);
  }

  const openLeaveDetail = (leave: LeaveDetails) => {
    setSelectedLeave(leave);
    setIsLeaveDetailOpen(true);
  }

  const approveLeave = async () => {
    console.log("approve leave", selectedLeave);
    if (!selectedLeave) return;
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave("3");
      if (res.data.Response) {
        notification.success({
          message: "Leave Approved",
          description: res.Response,
        });
      }
    } catch (error) {
      if (!error.response.data.ModelState) {
        notification.error({
          message: "Leave Approve Failed",
          description: "An error occurred while approving leave",
        });
      }
      const errorDescription = Object.keys(error.response.data.ModelState)
        .reduce((acc, key) => {
          error.response.data.ModelState[key].forEach((message: string) => {
            acc.push(message);
          });
          return acc;
        }, [])
        .join(", ");
      notification.error({
        message: "Leave Approve Failed",
        description: errorDescription,
      });
    }
    setIsModalLoading(false);
    closeLeaveDetail();
  };

  const decline = async () => {
    console.log("decline leave", selectedLeave);
    setIsModalLoading(true);
    try {
      const res = await updateSelectedLeave("2");
      console.log("leave declined");
      if (res.data.Response) {
        notification.success({
          message: "Leave Declined",
          description: res.Response,
        });
      }
    } catch (error) {
      if (!error.response.data.ModelState) {
        notification.error({
          message: "Leave Approve Failed",
          description: "An error occurred while approving leave",
        });
      }
      const errorDescription = Object.keys(error.response.data.ModelState)
        .reduce((acc, key) => {
          error.response.data.ModelState[key].forEach((message: string) => {
            acc.push(message);
          });
          return acc;
        }, [])
        .join(", ");
      notification.error({
        message: "Leave Declined Failed",
        description: errorDescription,
      });
    }
    setIsModalLoading(false);
    closeLeaveDetail();
  };

  const closeLeaveDetail = () => {
    setIsLeaveDetailOpen(false);
    setIsEdit(false);
  }

  const saveLeave = () => {
    console.log('save leave', selectedLeave)
  }

  const editLeave = () => {
    setIsEdit(true);
  }

  const onFilterChange = (filter: any) => {
    console.log('filter', filter);  
    setFilter(filter);
  }

  const filterModalOk = async () => {
    console.log('filter modal ok');
    setIsFilterOpen(false);
    setTeamLeaveData([]);
    setPagination({...DefaultPagination});
    setIsLoading(true);
    await loadTeamLeaveHistory(null, true);
    setIsLoading(false);
  }
  return (
    <>
      <div style={{ background: "white", padding: "20px" }}>
        <Row justify="space-between" align="middle">
          <Select
            style={{ minWidth: 160, maxWidth: 220 }}
            allowClear
            placeholder="Select Team Member"
            options={teamMembers}
            popupMatchSelectWidth={false}
            onChange={onSelectEmployee}
          />
          <Button icon={<FilterOutlined />} onClick={() => setIsFilterOpen(true)}>Filters</Button>
        </Row>
        <Text
          style={{
            marginTop: "4px",
            display: "inline-block",
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          Showing {teamLeaveData.length} leave applications of{" "}
          {pagination.totalItems} with a status of submitted, sorted by start date
        </Text>
      </div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} 
        spinning={isLoading}
        size="large" >
        <div style={{ padding: "10px 20px", minHeight: 500 }}>
          {teamLeaveData.map((leave, index) => {
            if (selectedEmployee && selectedEmployee !== leave.employeeNo) return null;
            return <TeamLeaveCard key={index} leave={leave} index={index} showDetail={openLeaveDetail} />
          })}
          {
            (pagination.totalPages && pagination.totalPages > pagination.current) && (
              <div className="text-center">
                <Button style={{ margin: "20px 0" }} onClick={loadMoreLeaveHistory}>
                  Load More 
                </Button>
              </div>
            )
          }
        </div>
      </Spin>
      <Modal 
        title="Filter"
        width={350}
        open={isFilterOpen}
        onCancel={() => setIsFilterOpen(false)}
        onOk={() => filterModalOk()}>
        <TeamLeaveHistoryFilter onFilterChange={onFilterChange} />
      </Modal>
      <Modal
        className='full-screen-modal'
        title="Leave Detail"        
        open={isLeaveDetailOpen}
        onCancel={closeLeaveDetail}
        footer={(
          <>
            {
              !isEdit && <Button onClick={() => editLeave()}>Edit</Button>
            }
            <Button type='primary' onClick={() => approveLeave()}>Approve</Button>
            {
              isEdit && <Button type='primary' onClick={() => saveLeave()}>Save</Button>
            }
            
            <Button type='primary' color="danger" onClick={() => decline()}>Decline</Button>
            <Button onClick={closeLeaveDetail}>Close</Button>
          </>
          
        )}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin
          />} spinning={isModalLoading}
          size="large">
          {
            isEdit ? <ApplyLeave isTeamLeave={true} leave={selectedLeave}></ApplyLeave>
             : <LeaveDetail isManager={true} isTeamLeave={true} leave={selectedLeave}></LeaveDetail>
          }
          </Spin>
        
      </Modal>
    </>
  );
};

export default TeamLeaveTab;