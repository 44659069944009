import { ThemeConfig } from "antd";

const color = {
  primary: '#1268b3',
  lightGray2: '#f7f9fe',
  gray6: '#8a99ab',
  gray1: '#344356',
  shadow1: '0 1px 3px rgba(0, 0, 0, 0.3)',
}

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#1268b3",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 14,
    // typographyTitleMarginTop: 0,
    
  },
  components: {
    Button: {
      colorLink: color.primary,
      colorText: color.gray6,
      colorBorder: color.gray6,
      contentFontSizeLG: 16,
      fontWeight: 700,
    },
    Table: {
      cellPaddingBlock: 8,
      cellPaddingInline: 12,
      headerBg: "rgb(255, 255, 255)",
      rowHoverBg: "rgba(12, 150, 228, 0.3)",
      fontSize: 14,
    },
    Typography: {
      titleMarginTop: 0,
      titleMarginBottom: 0,
    },
    
    Input: {
      // colorBgBase: "#1268b3",
    },
    Select: {
      // selectorBg: color.lightGray2,
      optionFontSize: 21,
    },
  },
  hashed: false 
  
}

export default {
  theme,
  color
};