import axios from "axios";

axios.defaults.baseURL = "http://localhost:3000/";
const soaHost = import.meta.env.VITE_APP_SOA_HOST;
axios.defaults.withCredentials = true;


// https://appgw.testaffinitylogon.com/soa/api/Leave/CanManage/43749
function getCanManageLeave(employeeNo: number) {
  return axios.get(`${soaHost}/Leave/CanManage/${employeeNo}`)
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

// https://leave.testaffinitylogon.com/ManagerTeamLeaveHistory/43749?PageNo=1&StatusCode=0&DateFrom=27-Sep-2023&orderBy=DateSubmitted&isAscending=true
function getManagerTeamLeaveHistory(employeeNo: number, data: any) {
  // const query = {
  //   PageNo
  //   StatusCode
  //   DateFrom
  //   orderBy
  //   isAscending
  // }
  return axios.get(`${soaHost}/LeaveHistory/ManagerTeam/${employeeNo}`, { params: data })
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function getLeaveManagerConfig(employeeNo: number) {
  return axios.get(`${soaHost}/Leave/ManagerConfig/${employeeNo}`)
    .then((res) => {
      
      const data = changeKeysToLowerCase(res.data);
      
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

// https://leave.testaffinitylogon.com/LeaveManagerEmployeeConfig/46323
function getLeaveManagerEmployeeConfig(employeeNo: number) {
  return axios.get(`${soaHost}/Leave/ManagerEmployeeConfig/${employeeNo}`)
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

// https://leave.testaffinitylogon.com/EmployeeLeaveBalance/46323?dateTo=26-Sep-2024&leaveCode=07&tsGroupIdToIgnore=&firstPerson=false
function getEmployeeLeaveBalance(employeeNo: number, params: any) {  
  return axios.get(`${soaHost}/LeaveBalance/Employee/${employeeNo}`, { params })
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

//https://leave.testaffinitylogon.com/CalculateLeaveUnits/46323/26-Sep-2024/27-Sep-2024/07
function getCalculateLeaveUnits(employeeNo: number, dateFrom: string, dateTo: string, leaveCode: string) {
  return axios.get(`${soaHost}/LeaveUnit/CalculateUnits/${employeeNo}/${dateFrom}/${dateTo}/${leaveCode}`)
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function changeKeysToLowerCase(obj: any): any {
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    const newObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = changeKeysToLowerCase(obj[key]);
      }
    }
    return newObj;
  } else if (Array.isArray(obj)) {
    return obj.map(item => changeKeysToLowerCase(item));
  }
  return obj;
}

// https://leave.testaffinitylogon.com/LeaveConfig/43749
function getLeaveConfig(employeeNo: number) {
  return axios.get(`${soaHost}/Leave/Config/${employeeNo}`)
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

// https://leave.testaffinitylogon.com/MyLeaveHistory/43749
function getMyLeaveHistory(employeeNo: number) {
  return axios.get(`${soaHost}/LeaveHistory/Employee/${employeeNo}`)
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}


// /LeaveDetail/62778/4806292
function getLeaveDetail(employeeNo: number, tsGroupId: number) {
  return axios.get(`${soaHost}/Leave/Application/${employeeNo}/${tsGroupId}`)
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}


function UpdateLeaveV2(tsGroupId: number, data: any) {
/* 
{
  "calcUnit": "H",
  "dateFrom": "08-Oct-2024",
  "dateTo": "08-Oct-2024",
  "empComment": "te",
  "employeeNo": 46323,
  "leaveStatus": 3,
  "leaveUnits": [
    {
      "tsGroupId": 4806295,
      "leaveDate": "08-Oct-2024",
      "positionCode": "92000199",
      "hoursAppliedFor": 7.6
    }
  ],
  "managerNotes": null,
  "partDayReason": "No Start/Finish Time info has been provided.",
  "payCode": "07",
  "reason": null,
  "timeLastModified": "2024-10-07T12:06:55",
  "tsGroupId": 4806295
}
  */

  return axios.patch(`${soaHost}/LeavesV2/${tsGroupId}`, data)

}

// {"EmployeeNo":"64259","PositionCode":"92000198","LeaveCode":"07","DateFrom":"22/Oct/2024","DateTo":"22/Oct/2024","ReasonCode":"Carers","TotalUnits":"7.6","SubmittedBy":43749,"Authorisations":[{"PositionCode":"92000198","SubmittedTo":"41931"}],"Comment":"","StatusCode":0,"UnitType":"H","Reply":null,"Units":[{"Date":"2024-10-22T00:00:00","PositionCode":"92000198","Hours":7.6,"HoursWorkSched":7.6,"Days":1}],"PartDayReason":""}
// https://leave.testaffinitylogon.com/LeaveApplication/64259

function createLeaveApplication(employeeNo: number, leave: any) {
  return axios.post(`${soaHost}/Leave/LeaveApplication/${employeeNo}`, leave)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function deleteLeave(employeeNo: number, tsGroupId: number) {
  return axios.delete(`${soaHost}/Leave/Application/${employeeNo}/${tsGroupId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

// https://leave.testaffinitylogon.com/GetLeaveAttachment/62778/4806292/12712
function getLeaveAttachment(employeeNo: number, tsGroupId: number, attachmentId: number, fileName: string) {
  return axios.get(`${soaHost}/Leave/Attachment/${employeeNo}/${tsGroupId}/${attachmentId}`, { responseType: 'blob' })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return '';
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

// formData 
// fileUpload
// TODO: create leave attachment after apply leave
function createLeaveAttachment(employeeNo: number, leaveId: number, fileUpload: any) {
  const formData = new FormData();
  formData.append('fileUpload', fileUpload);

  return axios.post(`${soaHost}/Leave/Attachment/${employeeNo}/${leaveId}`, formData)
  
}

// https://leave.testaffinitylogon.com/ManagerTeamLeaveBalance/43749?dateTo=30-Oct-2024&includeIndirect=false&employeeNo=0&leaveCode=07&pageNo=1
// LeaveBalance/ManagerTeam
/*
{
  "dateTo": "30-Oct-2024",
  "includeIndirect": false,
  "employeeNo": 0,
  "leaveCode": "07",
  "pageNo": 1
}
*/
function managerTeamLeaveBalance(employeeNo: number, params: any) {
  return axios.get(`${soaHost}/LeaveBalance/ManagerTeam/${employeeNo}`, { params: params })
    .then((res) => {
      const data = changeKeysToLowerCase(res.data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export default {
  getCanManageLeave,
  getManagerTeamLeaveHistory,
  getLeaveManagerConfig,
  getLeaveManagerEmployeeConfig,
  getEmployeeLeaveBalance,
  getCalculateLeaveUnits,
  getLeaveConfig,
  getMyLeaveHistory,
  getLeaveDetail,
  getLeaveAttachment,
  createLeaveAttachment,
  createLeaveApplication,
  deleteLeave,
  managerTeamLeaveBalance,
  UpdateLeaveV2,
}