import { LeaveStatus } from '@/components/constant';
import remote from '@/remote/remote';
import { stat } from 'fs';
import { createContext, useReducer, ReactNode, Dispatch, useContext } from 'react';

// Define the shape of the state
interface State {
  leaveConfig: any;
  leaveDetail: any; // leaveDetail received from API
  leaveBalance: any;
  leaveCode: any; // Added leaveCode
  selectedLeave?: any;
  user: any; // Added user
}

// Define the initial state
const initialState: State = {
  leaveConfig: {},
  leaveDetail: {},
  leaveBalance: {},
  leaveCode: {}, // Added leaveCode
  selectedLeave: {},
  user: {} // Added user
};

// Define action types
type Action =
  | { type: 'SET_LEAVE_CONFIG'; payload: any }
  | { type: 'SET_LEAVE_DETAIL'; payload: any }
  | { type: 'SET_LEAVE_BALANCE'; payload: any }
  | { type: 'SET_LEAVE_CODE'; payload: any }
  | { type: 'SET_SELECTED_LEAVE'; payload: any }
  | { type: 'SET_USER'; payload: any }; // Added SET_USER action

// Create the reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LEAVE_CONFIG':
      return { ...state, leaveConfig: action.payload };
    case 'SET_LEAVE_DETAIL':
      return { ...state, leaveDetail: action.payload };
    case 'SET_LEAVE_BALANCE':
      return { ...state, leaveBalance: action.payload };
    case 'SET_LEAVE_CODE': // Added case for SET_LEAVE_CODE
      return { ...state, leaveCode: action.payload };
    case 'SET_SELECTED_LEAVE':
      return { ...state, selectedLeave: action.payload };
    case 'SET_USER': // Added case for SET_USER
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

interface MyLeaveStoreContextProps {
  state: State;
  dispatch: Dispatch<Action>;
  saveSelectedLeave: (statusCode?: LeaveStatus) => void;
}

// Create context
const MyLeaveStoreContext = createContext<MyLeaveStoreContextProps>({
  state: initialState,
  dispatch: () => undefined,
  saveSelectedLeave: (statusCode?: LeaveStatus) => undefined
});

interface MyLeaveStoreProviderProps {
  children: ReactNode;
}

// Create a provider component
const MyLeaveStoreProvider = ({ children }: MyLeaveStoreProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const saveSelectedLeave = async (statusCode: LeaveStatus = LeaveStatus.Pending) => {
    console.log('save selected leave', state.selectedLeave);
    // "EmployeeNo":"64259","PositionCode":"92000198","LeaveCode":"07","DateFrom":"22/Oct/2024","DateTo":"22/Oct/2024","ReasonCode":"Carers","TotalUnits":"7.6","SubmittedBy":43749,"Authorisations":[{"PositionCode":"92000198","SubmittedTo":"41931"}],"Comment":"","StatusCode":0,"UnitType":"H","Reply":null,"Units":[{"Date":"2024-10-22T00:00:00","PositionCode":"92000198","Hours":7.6,"HoursWorkSched":7.6,"Days":1}],"PartDayReason":""
    // dispatch({ type: 'SET_LEAVE_DETAIL', payload: selectedLeave });
    const units = formatDaysIntoUnits(state.selectedLeave.days);
    const params = {
      "EmployeeNo": state.selectedLeave.employeeNo,
      "PositionCode": state.selectedLeave.positionCode,
      "LeaveCode": state.selectedLeave.leaveCode,
      "DateFrom": state.selectedLeave.dateFrom,
      "DateTo": state.selectedLeave.dateTo,
      "ReasonCode": state.selectedLeave.reasonCode,
      
      "SubmittedBy": state.selectedLeave.employeeNo,
      "Authorisations": state.selectedLeave.authorisations.map((auth: any) => ({SubmittedTo: auth.employeeNo.toString(), PositionCode: auth.positionCode})),
      "Comment": state.selectedLeave.comment,
      "StatusCode": statusCode,
      "UnitType": state.selectedLeave.unitType,
      "Reply": null,
      "Units": units,
      "TotalUnits": state.selectedLeave.unitType === 'H' ? state.selectedLeave.totalHours : state.selectedLeave.totalDays,
      "PartDayReason": ""
    }
    console.log('params', params);
    return remote.createLeaveApplication(state.selectedLeave.employeeNo, params)
  }

  const formatDaysIntoUnits = (days: []) => {
    let units = days.map((day: any) => {
      const daysUnit = day.positionUnits[0]
      return {
        "Date": day.date,
        "PositionCode": state.selectedLeave.positionCode,
        "Hours": daysUnit.hoursAppliedFor,
        "HoursWorkSched": daysUnit.hoursWorkScheduled,
        "Days": daysUnit.daysAppliedFor
      }
    })
    return units;
    // dispatch({ type: 'SET_SELECTED_LEAVE', payload: { ...state.selectedLeave, units } });
  }

  return (
    <MyLeaveStoreContext.Provider value={{ state, dispatch, saveSelectedLeave }}>
      {children}
    </MyLeaveStoreContext.Provider>
  );
};

// Custom hook to use the store context more easily
const useMyLeaveStore = () => {
  const context = useContext(MyLeaveStoreContext);
  if (!context) {
    throw new Error('useMyLeaveStore must be used within a MyLeaveStoreProvider');
  }
  return context;
};

export { MyLeaveStoreProvider, useMyLeaveStore };
