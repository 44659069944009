import remote from '@/remote/remote';
import { createContext, useReducer, ReactNode, Dispatch, useContext } from 'react';
// Define the shape of the state
interface State {
  leaveCodes: any[];
  employees: any[];
  managers: any[];
  forwardToManagers: any[];
  teamLeaveHistory: any[];
  leaveConfig: any; // Add new state property
  selectedLeaveDetail: any;
  allLeaveCodes: any[]; // Add new state property
  selectedLeaveUnit: any;
}

// Define the initial state
const initialState: State = {
  leaveCodes: [],
  employees: [],
  managers: [],
  forwardToManagers: [],
  teamLeaveHistory: [],
  leaveConfig: {},
  selectedLeaveDetail: {},
  allLeaveCodes: [] ,
  selectedLeaveUnit: {}
};

// Define action types
type Action =
  | { type: 'SET_LEAVE_CODES'; payload: string[] }
  | { type: 'SET_EMPLOYEES'; payload: string[] }
  | { type: 'SET_FORWARD_TO_MANAGERS'; payload: string[] }
  | { type: 'SET_TEAM_LEAVE_HISTORY'; payload: any[] }
  | { type: 'SET_LEAVE_CONFIG'; payload: any }
  | { type: 'SET_SELECTED_LEAVE_DETAIL'; payload: any }
  | { type: 'SET_ALL_LEAVE_CODES'; payload: any[] }
  | { type: 'SET_SELECTED_LEAVE_UNIT'; payload: any };

// Create the reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LEAVE_CODES':
      return { ...state, leaveCodes: action.payload };
    case 'SET_EMPLOYEES':
      return { ...state, employees: action.payload };
    case 'SET_FORWARD_TO_MANAGERS':
      return { ...state, forwardToManagers: action.payload };
    case 'SET_TEAM_LEAVE_HISTORY':
      return { ...state, teamLeaveHistory: action.payload };
    case 'SET_LEAVE_CONFIG': 
      return { ...state, leaveConfig: action.payload };
    case 'SET_SELECTED_LEAVE_DETAIL':
      return { ...state, selectedLeaveDetail: action.payload };
    case 'SET_ALL_LEAVE_CODES':
      return { ...state, allLeaveCodes: action.payload };
    case 'SET_SELECTED_LEAVE_UNIT':
      return { ...state, selectedLeaveUnit: action.payload };
    default:
      return state;
  }
};

interface StoreContextProps {
  state: State;
  dispatch: Dispatch<Action>;
  saveSelectedLeave: () => Promise<any>;
  updateSelectedLeave: (leaveStatus: string) => Promise<any>;
}

// Create context
const StoreContext = createContext<StoreContextProps>({
  state: initialState,
  dispatch: () => undefined,
  saveSelectedLeave: async () => {},
  updateSelectedLeave: () => {},
});

interface StoreProviderProps {
  children: ReactNode;
}

// Create a provider component
const StoreProvider = ({ children }: StoreProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const saveSelectedLeave = () => {
    console.log('save selected leave', state.selectedLeaveDetail);
    // "EmployeeNo":"64259","PositionCode":"92000198","LeaveCode":"07","DateFrom":"22/Oct/2024","DateTo":"22/Oct/2024","ReasonCode":"Carers","TotalUnits":"7.6","SubmittedBy":43749,"Authorisations":[{"PositionCode":"92000198","SubmittedTo":"41931"}],"Comment":"","StatusCode":0,"UnitType":"H","Reply":null,"Units":[{"Date":"2024-10-22T00:00:00","PositionCode":"92000198","Hours":7.6,"HoursWorkSched":7.6,"Days":1}],"PartDayReason":""
    // dispatch({ type: 'SET_LEAVE_DETAIL', payload: selectedLeave });
    const units = formatDaysIntoUnits(state.selectedLeaveDetail.days);
    const params = {
      "EmployeeNo": state.selectedLeaveDetail.employeeNo,
      "PositionCode": state.selectedLeaveDetail.positionCode,
      "LeaveCode": state.selectedLeaveDetail.leaveCode,
      "DateFrom": state.selectedLeaveDetail.dateFrom,
      "DateTo": state.selectedLeaveDetail.dateTo,
      "ReasonCode": state.selectedLeaveDetail.reasonCode,
      
      "SubmittedBy": state.selectedLeaveDetail.employeeNo,
      "Authorisations": state.selectedLeaveDetail.authorisations.map((auth: any) => ({SubmittedTo: auth.employeeNo.toString(), PositionCode: auth.positionCode})),
      "Comment": state.selectedLeaveDetail.comment,
      "StatusCode": 0,
      "UnitType": state.selectedLeaveDetail.unitType,
      "Reply": null,
      "Units": units,
      "TotalUnits": state.selectedLeaveDetail.unitType === 'H' ? state.selectedLeaveDetail.totalHours : state.selectedLeaveDetail.totalDays,
      "PartDayReason": ""
    }
    console.log('params', params);
    return remote.createLeaveApplication(state.selectedLeaveDetail.employeeNo, params)
  }

  const updateSelectedLeave = (leaveStatus = '0') => {
    console.log('update selected leave', state.selectedLeaveDetail);
    console.log('state.selectedLeaveUnit', state.selectedLeaveUnit)
    const param = {
      payCode: state.selectedLeaveDetail.leaveCode,
      calcUnit: "H",
      dateFrom: state.selectedLeaveDetail.dateFrom,
      dateTo: state.selectedLeaveDetail.dateTo,
      employeeNo: state.selectedLeaveDetail.employeeNo,
      reason: state.selectedLeaveDetail.reasonCode,
      managerNotes: state.selectedLeaveDetail.managerComment,
      empComment: state.selectedLeaveDetail.comment,
      partDayReason: state.selectedLeaveDetail.partDayReason,
      timeLastModified: state.selectedLeaveDetail.timeLastModified,
      tsGroupId: state.selectedLeaveDetail.tSGroupId,
      leaveUnits: state.selectedLeaveUnit.units.map((unit: any) => ({
        daysAppliedFor: unit.daysAppliedFor,
        defaultHoursWorkScheduled: null,
        hoursAppliedFor: unit.hoursAppliedFor,
        hoursWorkSched: unit.hoursWorkScheduled,
        isPublicHoliday: false,
        leaveDate: unit.date,
        positionCode: state.selectedLeaveUnit.positionCode,
        publicHolidayName: "",
        tsGroupId:  state.selectedLeaveDetail.tSGroupId
      })),
      leaveStatus: leaveStatus,
    };
    return remote.UpdateLeaveV2(state.selectedLeaveDetail.tSGroupId, param)
  }

  const formatDaysIntoUnits = (days: []) => {
    let units = days.map((day: any) => {
      const daysUnit = day.positionUnits[0]
      return {
        "Date": day.date,
        "PositionCode": state.selectedLeaveDetail.positionCode,
        "Hours": daysUnit.hoursAppliedFor,
        "HoursWorkSched": daysUnit.hoursWorkScheduled,
        "Days": daysUnit.daysAppliedFor
      }
    })
    return units;
    // dispatch({ type: 'SET_SELECTED_LEAVE', payload: { ...state.selectedLeave, units } });
  }
  return (
    <StoreContext.Provider value={{ state, dispatch, saveSelectedLeave, updateSelectedLeave }}>
      {children}
    </StoreContext.Provider>
  );
};

// Custom hook to use the store context more easily
const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};

export { StoreProvider, useStore };