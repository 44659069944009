import remote from "./remote";

function createResource(promise: Promise<any>) {
  let status: "pending" | "success" | "error" = "pending";
  let result: any;
  const suspender = promise.then(
    (res: any) => {
      status = "success";
      result = res;
    },
    (err: any) => {
      status = "error";
      result = err;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender; // Triggers Suspense fallback
      } else if (status === "error") {
        throw result; // Handle error
      } else if (status === "success") {
        return result;
      }
    },
  };
}

export function fetchCanManageLeave(id: number) {
  return createResource(remote.getCanManageLeave(id));
}