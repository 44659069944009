import { Button, Checkbox, DatePicker, Grid, Row, Select } from "antd"
import { useMemo, useState } from "react"
import dayjs from "dayjs"
import { DISPLAY_DATE_FORMAT } from "../constant"
import { useStore } from "@/store/store"

export const DefaultTeamHistoryFilter = {
  status: "0",
  leaveType: "all",
  orderBy: "startDate",
  dateFrom: null,
  dateTo: null,
  indirect: false,
  toAction: false,
  showSubmitted: false,
  isAscending: true
}

export interface TeamLeaveHistoryFilterProps {
  onFilterChange: (filter: typeof DefaultTeamHistoryFilter) => void
}

const TeamLeaveHistoryFilter = (props: TeamLeaveHistoryFilterProps) => {
  const [filter, setFilter] = useState(DefaultTeamHistoryFilter)
  const { state, dispatch } = useStore(); 

  const leaveTypeOptions = useMemo(() => {
    return state.allLeaveCodes.map((leaveCode: any) => ({ label: leaveCode.description, value: leaveCode.leaveCode }))
  }, [state.allLeaveCodes])
  const handleChange = (key: string, value: any) => {
    const newFilter = {
      ...filter,
      [key]: value
    }
    setFilter(newFilter)
    props.onFilterChange(newFilter)
  }

  return (
    <>
      <div className="team-leave-history-filter">
        <div>Status</div>
        <Select
          defaultValue="All"
          value={filter.status}
          onChange={(value) => handleChange("status", value)}
          options={[
            { label: 'All', value: 'all' },
            { label: 'Pending', value: '0' },
            { label: 'Declined', value: '2' },
            { label: 'Approved', value: '3' },
            { label: 'Cancelled', value: '6' },
            // { label: 'Cancel Request', value: 'cancelRequest' },
          ]}
        ></Select>
      
        <div>Leave Type</div>
        <Select
          defaultValue="All"
          style={{maxWidth: 170}}
          value={filter.leaveType}
          onChange={(value) => handleChange("leaveType", value)}
          options={leaveTypeOptions}
        ></Select>
      
        <div>Sort By</div>
        <Select
          defaultValue="Start Date"
          value={filter.orderBy}
          onChange={(value) => handleChange("orderBy", value)}
          options={[
            { label: 'Start Date', value: 'startDate' },
            { label: 'End Date', value: 'endDate' },
            { label: 'Submitted Date', value: 'submittedDate' },
          ]}
        ></Select>
        <div>Ascending</div>
        <Checkbox
          checked={filter.isAscending}
          onChange={(e) => handleChange("isAscending", e.target.checked)}
        />

        <div>Date From</div>
        <DatePicker
          value={filter.dateFrom ? dayjs(filter.dateFrom) : null}
          format={DISPLAY_DATE_FORMAT}
          onChange={(date) => handleChange("dateFrom", date ? date.toISOString() : null)}
        />
        
        <div>Date To</div>
        <DatePicker
          value={filter.dateTo ? dayjs(filter.dateTo) : null}
          format={DISPLAY_DATE_FORMAT}
          onChange={(date) => handleChange("dateTo", date ? date.toISOString() : null)}
        />
        <div>Include Indirect</div>
        <Checkbox onChange={(e) => handleChange("indirect", e.target.checked)}
          checked={filter.indirect}></Checkbox>

        <div>Leave To Action</div>
        <Checkbox
          checked={filter.toAction}
          onChange={(e) => handleChange("toAction", e.target.checked)}
        />

        <div>Show Submitted Date</div>
        <Checkbox
          checked={filter.showSubmitted}
          onChange={(e) => handleChange("showSubmitted", e.target.checked)}
        />
      </div>
      
    </>
  )
}

export default TeamLeaveHistoryFilter;