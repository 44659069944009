import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import { ConfigProvider } from "antd";
import customTheme from './styles/customTheme.ts'
import './index.css'

import React from "react";
import ReactDOM from "react-dom/client";
// import App from "./App.tsx";

// import "./index.css";
// import "./styles/index.less";
// import "react-perfect-scrollbar/dist/css/styles.css";
// import { ConfigProvider } from "antd";
// import CustomTheme from "./styles/customTheme";

declare global {
  interface Window {
    Affinity: {
      user?: any;
      app: string;
    };
  }
}

window.Affinity = {
  app: "Leave",
};
function loadStylesheet(href: string) {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = href;
  document.head.appendChild(link);
}

const host = import.meta.env.VITE_APP_DASHBOARD_HOST;
fetch(`${host}/manifest.json`)
  .then((res) => {
    return res.json();
  })
  .then((manifest) => {
    const script = document.createElement("script");
    script.id = "dashboard";
    script.crossOrigin = "";
    let scriptSrc;

    if (manifest.files && manifest.files["main.js"]) {
      scriptSrc = `${host}${manifest.files["main.js"]}`;
    } else if (manifest["app.js"]) {
      scriptSrc = `${host}/${manifest["app.js"]}`;
    } else if (manifest["index.html"] && manifest["index.html"].file) {
      scriptSrc = `${host}/${manifest["index.html"].file}`;
    } else {
      console.error("No valid script source found in the manifest.");
    }

    if (scriptSrc) {
      script.src = scriptSrc;
    }
    let mainCss;
    if (manifest.files && manifest.files["main.css"]) {
      mainCss = `${host}${manifest.files["main.css"]}`;
    } else if (manifest["app.css"]) {
      mainCss = `${host}/${manifest["app.css"]}`;
    } else if (manifest["index.css"] && manifest["index.css"].file) {
      mainCss = `${host}/${manifest["index.css"].file}`;
    } else {
      console.error("No valid style source found in the manifest.");
    }
    loadStylesheet(mainCss!);

    script.onload = () => {
      ConfigProvider.config({
        prefixCls: "ss-app",
      });
      ReactDOM.createRoot(document.getElementById("root")!).render(
        <ConfigProvider prefixCls="ss-app" theme={customTheme.theme}>
          <App />
        </ConfigProvider>
      );
    };
    document.head.appendChild(script);
  });



// createRoot(document.getElementById('root')!).render(
//   <ConfigProvider  
//     prefixCls="ss-app"
//     theme={customTheme.theme}>
      
//     <App />
//   </ConfigProvider>,
// )
