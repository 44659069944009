import React, { useEffect } from 'react';
import { Button, DatePicker, InputNumber, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import theme from '@/styles/customTheme';
import { LIST_DATE_FORMAT, REQUEST_DATE_FORMAT } from '../constant';
import remote from '@/remote/remote';


const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const DatePickerFormat = 'dddd, DD MMMM YYYY';

interface DateRangeProps {
  selectedLeave: any
  onDateRangeChange: (dateRange: any, dateList: any[], totalDays: number, totalHours: number) => void
}

const DateRange: React.FC<DateRangeProps> = (props) => {
  const [dateRange, setDateRange] = React.useState<any>({})
  const [isEditDate, setIsEditDate] = React.useState(false)
  const [dateList, setDateList] = React.useState<any[]>([])
  const [totalDays, setTotalDays] = React.useState(0)
  const [totalHours, setTotalHours] = React.useState(0)
  const [totalWeeks, setTotalWeeks] = React.useState(0)
  const user = window.Affinity?.user;

  useEffect(() => {
    console.log('date range component', props.selectedLeave)
    if (props.selectedLeave.dateFrom && props.selectedLeave.dateTo) {
      const obj = {
        dateFrom: props.selectedLeave.dateFrom,
        dateTo: props.selectedLeave.dateTo
      }
      
      if (dateRange.dateFrom !== obj.dateFrom || dateRange.dateTo !== obj.dateTo) {
        setDateRange(obj)
      }
      
      console.log('obj', obj)
    } else if (!props.selectedLeave.dateFrom && !props.selectedLeave.dateTo) {
      setDateRange({
        dateFrom: dayjs().toISOString(),
        dateTo: dayjs().toISOString()
      })
    }
    
  }, [props.selectedLeave.dateFrom, props.selectedLeave.dateTo])

  useEffect(() => {
    if (dateRange.dateFrom && dateRange.dateTo && props.selectedLeave.leaveCode) {
      getCalculatedUnit()
    }
    // props.onDateRangeChange(dateRange, dateList)
    console.log('date range', dateRange)
  }, [dateRange, props.selectedLeave.leaveCode])

  useEffect(() => {
    const totalDays = dateList.reduce((acc: number, date: any) => {
      return acc + date.totalDaysAppliedFor
    }, 0)
    setTotalDays(totalDays)

    const totalHours = dateList.reduce((acc: number, date: any) => {
      return acc + date.totalHoursAppliedFor
    }, 0)
    setTotalHours(totalHours)
    props.onDateRangeChange(dateRange, dateList, totalDays, totalHours)
  }, [dateList])
  
  const getCalculatedUnit = () => {
    let requestUser;
    if (props.selectedLeave.employeeNo) {
      requestUser = props.selectedLeave.employeeNo
    } else {
      requestUser = user.employeeNo
    }
    remote.getCalculateLeaveUnits(requestUser, dateRange.dateFrom, dateRange.dateTo, props.selectedLeave.leaveCode)
    .then(res => {
      console.log('getCalculateLeaveUnits', res);
      setTotalDays(res.totalDaysAppliedFor)
      setTotalHours(res.totalHoursAppliedFor)
      setTotalWeeks(res.totalWeeksAppliedFor)
      setDateList(res.days)
    })
  }

  const updateDateListInput = (e: number, index: number) => {
    const value = e;
    const newDateList = dateList.map((date: any, i: number) => {
      if (i === index) {
        return {
          ...date,
          totalHoursAppliedFor: value
        }
      }
      return date;
    })
    setDateList(newDateList);
  }
  
  const onStartDateChange = (val: any) => {
    setDateRange({
      ...dateRange,
      dateFrom: val.toISOString()
    })
  }

  const onEndDateChange = (val: any) => {
    setDateRange({
      ...dateRange,
      dateTo: val.toISOString()
    })
  }

  return (
    <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <div>
          <Text>Start Date</Text>
        </div>
        <div style={{ marginTop: "4px" }}>
          <DatePicker
            size="large"
            onChange={onStartDateChange}
            className="apply-date-picker"
            value={dayjs(dateRange.dateFrom)}
            format={{
              format: DatePickerFormat,
            }}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <div>
            <Text>End Date</Text>
          </div>
          <div style={{ marginTop: "4px" }}>
            <DatePicker
              size="large"
              onChange={onEndDateChange}
              className="apply-date-picker"
              value={dayjs(dateRange.dateTo)}
              format={{
                format: DatePickerFormat,
              }}
            />
          </div>
        </div>
        {
          isEditDate &&
          <div style={{ marginTop: "10px"}}>
            {
              dateList.map((date: any, index: number) => (
                <Row style={{ marginTop: "10px"}} key={index} className="date-row" justify='space-between' align='middle'>
                  <Text>{dayjs(date.date).format(LIST_DATE_FORMAT)}</Text>
                  <span>
                    {
                      props.selectedLeave.unitType === 'D' 
                      ? <InputNumber style={{width: '60px'}} type='number' value={date.totalDaysAppliedFor} onChange={(e) => updateDateListInput(e, index)}></InputNumber>
                      : <InputNumber style={{width: '60px'}} type='number' value={date.totalHoursAppliedFor} onChange={(e) => updateDateListInput(e, index)}></InputNumber>
                    }
                    {
                      props.selectedLeave.unitType === 'D'
                      ? <span style={{marginLeft: '2px'}}>Days</span>
                      : <span style={{marginLeft: '2px'}}>Hours</span>
                    }
                  </span>                  
                </Row>
              ))
            }
        </div>
        }
        
        <Row
          justify="space-between"
          align="middle"
          style={{ marginTop: "10px" }}
        >
          <Text style={{ fontSize: "16px" }}>Total {totalDays} days / {totalHours} hours</Text>
          <Button
            type="text"
            style={{ color: theme.color.gray1 }}
            onClick={() => setIsEditDate(!isEditDate)}>
            Edit Date
          </Button>
        </Row>

            
        {/* <Row style={{ marginTop: '10px' }}>
          <Text style={{ fontSize: '16px' }}>
            Balance 56.25 hours
          </Text>
        </Row> */}
      </section>
  );
};

export default DateRange;