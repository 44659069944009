// leave balance component 

import { useEffect, useState } from "react";
// import { balanceSummaryMockData } from "../mockData/mockData";
import { Card, Col, Divider, Row, Typography } from "antd";
import remote from "@/remote/remote";
import { useMyLeaveStore } from "@/store/myLeaveStore";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const MyLeaveBalance = () => {
  // const [teamLeaveData, setTeamLeaveData] = useState(leaveMockData);
  // const [myLeaveData, setMyLeaveData] = useState(myleaveMockData);
  // const [balanceSummary, setBalanceSummary] = useState(balanceSummaryMockData);
  const [leaveBalance, setLeaveBalance] = useState<any[]>([]);
  const user = window.Affinity?.user;

  
  useEffect(() => {
    const today = dayjs().format('DD-MMM-YYYY');
    let params = {
      "dateTo": today,
      "includeIndirect": false,
      // "employeeNo": user.employeeNo,
      // "leaveCode": "07",
      "pageNo": 1
    }
    remote.getEmployeeLeaveBalance(user.employeeNo, params)
    .then((res) => {
      setLeaveBalance(res.componentBalances[0].codeBalances)
    })
  }, [])

  return (
    <Card>
      <Title level={4}>Balance Summary</Title>
      <Divider />
      {
        leaveBalance.map((leave) => {
          return (
            <div key={leave.leaveCode}>
              <Row justify="space-between" align="middle">
                <Text style={{fontSize: '18px'}}>{leave.codeDescription}</Text>
                {
                  leave.unitType === 'H' 
                  ? <div>
                      <div>{leave.totalHours} Hours</div>
                      <div style={{color: '#8a99ab'}}>{leave.totalDays} Days</div>
                    </div>
                  : <div>
                      <div>{leave.totalDays} Days</div>
                      <div style={{color: '#8a99ab'}}>{leave.totalHours} Hours</div>
                    </div>
                }
              </Row>
              <Divider />
            </div>
            
          )
        })
      }
      {/* <Row justify="space-between">
        <Text>Sick / Personal Leave</Text>
        <Text className='card-status'>Submitted</Text>
      </Row> */}
    </Card>
  )
}

export default MyLeaveBalance;