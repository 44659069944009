import React, { useEffect, useState } from 'react';
import { Button, Modal, notification, Row, Spin, Typography } from 'antd';
import MyLeaveCard from './MyLeaveCard';
import MyLeaveBalance from './MyLeaveBalance';
import { myleaveMockData } from '@/mockData/mockData';
import remote from '@/remote/remote';
// import { useStore } from '@/store/store';
import ApplyLeave from '../leaveDetail/ApplyLeave';
import LeaveDetail from '../leaveDetail/LeaveDetail';
import { LeaveDetails } from '../types';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import { LoadingOutlined } from '@ant-design/icons';
import { LeaveStatus } from '../constant';

const { Text } = Typography;

const MyLeaveTab = () => {
  const [myLeaveData, setMyLeaveData] = useState<any[]>([]);
  const [selectedLeave, setSelectedLeave] = useState<any>({});
  const [isLeaveDetailOpen, setIsLeaveDetailOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { state, dispatch, saveSelectedLeave } = useMyLeaveStore(); 
  const user = window.Affinity?.user;
  
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;

  useEffect(() => {
    // loadEmployeeLeaveData();
    setDataLoading(true);
    async function fetchData() {
      await getLeaveConfig();
      await getMyLeaveHistory();
      setDataLoading(false);
    }

    fetchData();
  }, [])

  const getLeaveConfig = async () => {
    try {
      const res = await remote.getLeaveConfig(user.employeeNo);
      console.log(res);

      dispatch({ type: 'SET_LEAVE_CONFIG', payload: res });
      dispatch({ type: 'SET_LEAVE_CODE', payload: res.leaveCodes });
    } catch (error) {
      console.error('Failed to get leave config:', error);
    }
  }

  const getMyLeaveHistory = async () => {
    try {
      const res = await remote.getMyLeaveHistory(user.employeeNo);
      console.log('my leave history', res);
      const newLeaveHistory = res.history.map((leave: LeaveDetails) => ({
        employeeName: leave.employeeName,
        employeeNo: leave.employeeNo,
        dateFrom: leave.dateFrom,
        dateTo: leave.dateTo,
        leaveCode: leave.leaveCode,
        codeDescription: leave.codeDescription,
        status: leave.status,
        totalDays: leave.totalDays,
        totalHours: leave.totalHours,
        unitType: leave.unitType,
        comment: leave.comment,
        authorisations: leave.authorisations,
        positionCode: leave.positionCode,
        positionTitle: leave.positionTitle,
        reasonCode: leave.reasonCode,
        reasonDescription: leave.reasonDescription,
        tsGroupId: leave.tSGroupId,
      }));
      setMyLeaveData([...myLeaveData, ...newLeaveHistory]);
      return res;
    } catch (error) {
      console.error('Failed to get leave history:', error);
    }
  }

  const getBalanceByCode = (leaveCode: string) => {
    return state.leaveCode.find((code: any) => code.leaveCode === leaveCode);
  }

  const editLeave = () => {
    setIsEdit(true);
  }

  const closeLeaveDetail = () => {
    setIsLeaveDetailOpen(false);
    setIsEdit(false);
  }
  
  const moreAction = (selectedLeave: any) => {
    // balance: getBalanceByCode(leave.leaveCode),
    setSelectedLeave(
      {...selectedLeave,
        balance: getBalanceByCode(selectedLeave.leaveCode)
      }
    );
    setIsLeaveDetailOpen(true);
  }

  const onSave = async () => {
    setDataLoading(true);
    try {
      await saveSelectedLeave(selectedLeave.leaveCode);
    } catch (error) {
      console.error('Failed to save leave:', error);
    }
    setDataLoading(false);
  }

  const onCancel = async () => {
    setModalLoading(true);
    console.log('delete leave', selectedLeave);
    const leave = {
      ...selectedLeave,
      status: LeaveStatus.Cancelled,
    }

    try {
      // await remote.createLeaveApplication(selectedLeave.employeeNo, leave)
      await saveSelectedLeave(LeaveStatus.Cancelled);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `'Delete leave failed'`,
        // description: <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>,
        placement: 'top',
      });

    }
    setModalLoading(false);
    
  }

  return (
    <div style={{ borderRadius: '6px', overflow: 'hidden' }}>
      <Spin spinning={dataLoading}
        indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} >
        <Row className='pane-first-row' justify="space-between" align="middle">
          <div>
        <span className='update-number'>3</span>
        <Text>My Updates</Text>
          </div>
          <Text>VIEW ALL</Text>
        </Row>
        {myLeaveData.map((leave, index) => (
          <MyLeaveCard key={index} leave={leave} index={index} moreAction={moreAction}/>
        ))}
        <MyLeaveBalance />
      </Spin>
      <Modal
        className='full-screen-modal'
        title="Leave Detail"        
        open={isLeaveDetailOpen}
        onCancel={closeLeaveDetail}
        footer={(
          <>
            {
              !isEdit && (
                <Button onClick={() => editLeave()}>Edit</Button>
              )
            }

            {
              isEdit && (
                <>
                  <Button type='primary' onClick={onSave}>Save</Button>
                  <Button type='primary' onClick={onCancel} danger>Cancel</Button>
                </>
              )
            }
            <Button onClick={closeLeaveDetail}>Close</Button>
          </>
          
        )}>
          <Spin spinning={modalLoading}>
          {
            isEdit ? <ApplyLeave isTeamLeave={false} leave={selectedLeave}></ApplyLeave>
             : <LeaveDetail isManager={true} isTeamLeave={false} leave={selectedLeave}></LeaveDetail>
          }
          </Spin>
      </Modal>
    </div>
  );
};

export default MyLeaveTab;