import { Suspense, useEffect, useState } from 'react'
import reactLogo from './assets/react.svg'
import viteLogo from '/vite.svg'
import './App.css'
import './styles/index.less'
import Leave from './components/Leave'
import { StoreProvider } from './store/store';
import { MyLeaveStoreProvider } from './store/myLeaveStore'
import remote from './remote/remote'
import {fetchCanManageLeave} from './remote/suspenseResource'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface CanManageLeaveResource {
  read: () => boolean;
}
interface LeaveComponentProps {
  canManageLeaveResource: CanManageLeaveResource;
}

const LeaveComponent = (props: LeaveComponentProps) => {
  const canManageLeave = props.canManageLeaveResource.read(); // This will throw if data isn't ready
  console.log('canManageLeave', canManageLeave);
  return canManageLeave ? <Leave canManageLeave={canManageLeave}/> : <div>Load User Fail</div>;
};

function App() {
  const [count, setCount] = useState(0)
  const [userLoaded, setUserLoaded] = useState(false);
  const [canManageLeaveResource, setCanManageLeaveResource] = useState(null);

  // useEffect(() => {
  //   fetchCanManageLeave(43749)
  // }, [])

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (window.Affinity?.user && window.Affinity.user.employeeNumber) {
        if (!window.Affinity.user.employeeNo) {
          window.Affinity.user.employeeNo = window.Affinity.user.employeeNumber;
        }
        setUserLoaded(true);
        observer.disconnect(); // Stop observing once user is loaded
      }
    });

    // Observe the body for mutations (you can fine-tune the target)
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (userLoaded) {
      console.log("Affinity.user is now available", window.Affinity.user);

      const resource = fetchCanManageLeave(window.Affinity.user.employeeNo);
      setCanManageLeaveResource(resource);
    }
  }, [userLoaded]);
  
  if (!userLoaded) {
    return (
      <div className='Leave-container'>
        <div className='inital-loading-wrapper'>
          <LoadingOutlined style={{ fontSize: 64 }} spin />
        </div>
      </div>
    );
  }
  return (
    <>
      <MyLeaveStoreProvider>
        <StoreProvider>
          <Suspense
            fallback={
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <LoadingOutlined style={{ fontSize: 64 }} spin />
              </div>
            }
          >
            {canManageLeaveResource && <LeaveComponent canManageLeaveResource={canManageLeaveResource} />}
          </Suspense>
        </StoreProvider>  
      </MyLeaveStoreProvider>
      
      
    </>
  )
}

export default App
